
































import useSelectItems from "@/use/selectItems";
import { defineComponent, reactive } from "@vue/composition-api";

export default defineComponent({
  components: {
    OParticipantList: () =>
      import(
        "@/components/organisms/event/massPrinting/o-participant-list.vue"
      ),
  },
  setup(_, { root }) {
    const state = reactive({
      search: "",
      status: "participant",
    });

    const { participantStatusItems } = useSelectItems({ root });

    return { state, participantStatusItems };
  },
});
